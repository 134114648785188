import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import {
  Box,
  Chip,
  Avatar,
  IconButton,
  InputAdornment,
  SvgIcon,
  TextField,
  makeStyles,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AddIcon from '@material-ui/icons/Add';
import countries from '../data/countries.json';
import platforms from '../data/platforms.json';

import igIcon from '../assets/images/instagram.svg';
import tiktokIcon from '../assets/images/tik-tok.svg';
import fbIcon from '../assets/images/facebook.svg';
import snapchatIcon from '../assets/images/snapchat.svg';
import ytIcon from '../assets/images/youtube.svg';

const useStyles = makeStyles(theme => ({
  root: {},
  input: {
    color: '#FFFF',
    '&.MuiInput-underline:before': {
      borderBottom: '1px solid #FFFF !important',
    },
    '&.MuiInput-underline:after': {
      borderBottom: '1px solid #FFFF !important',
    },
    '& .MuiInputBase-input': {
      color: '#FFFF',
    },
    '& .MuiSelect-icon': {
      color: '#FFFF',
    },
    '& .MuiSelect-select:not([multiple]) option': {
      backgroundColor: '#222222 !important',
    },
  },
  platformTextField: {
    marginRight: 11,
    '& .MuiSelect-select.MuiSelect-select': {
      paddingRight: 45,
      width: '100%',
      minWidth: '100%',
    },
    '&.MuiInput-underline:before': {
      borderBottom: '1px solid #FFFF !important',
    },
    '&.MuiInput-underline:after': {
      borderBottom: '1px solid #FFFF !important',
    },
    '& .MuiInputBase-input': {
      color: '#FFFF',
    },
    '& .MuiSelect-icon': {
      color: '#FFFF',
      right: -5,
    },
    '& .MuiSelect-select:not([multiple]) option': {
      backgroundColor: '#222222 !important',
    },
    color: '#FFFF',
    '& .MuiFormLabel-root.Mui-focused': { color: '#FFFF' },
    '& .MuiFormLabel-root': { color: '#FFFF' },
  },
  textField: {
    color: '#FFFF',
    '& .MuiFormLabel-root.Mui-focused': { color: '#FFFF' },
    '& .MuiFormLabel-root': { color: '#FFFF' },
  },
  svgIcon: { color: '#FFFF' },
  chip: {
    backgroundColor: '#FFF',
    margin: '3px',
  },
  iconButton: {
    background: 'linear-gradient(#fdcc52,#fdc539)',
  },
  box: { display: 'flex', alignItems: 'center' },
  otherAccountBox: { display: 'flex', alignItems: 'baseline' },
  inputAdornment: {
    marginBottom: theme.spacing(1),
  },
}));

const platformIcon = {
  TIKTOK: tiktokIcon,
  FACEBOOK: fbIcon,
  SNAPCHAT: snapchatIcon,
  YOUTUBE: ytIcon,
};

function AccountForm({ referralId }) {
  const classes = useStyles();
  const {
    handleSubmit,
    clearErrors,
    reset,
    getValues,
    watch,
    control,
    errors,
  } = useForm();

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [info, setInfo] = useState('');
  const [country, setCountry] = useState('');
  const [platform, setPlatform] = useState('');
  const [instaValue, setInstaValue] = useState('');
  const [otherAccountValue, setOtherAccountValue] = useState('');
  const [instaAccounts, setInstaAccounts] = useState([]);
  const [otherAccounts, setOtherAccounts] = useState([]);
  const [otherAccountsChips, setOtherAccountsChips] = useState([]);
  const platformSelected = watch('platform', '');
  const values = getValues();

  const handleCountryChange = event => {
    setCountry(event.target.value);
  };

  const handlePlatformChange = event => {
    setPlatform(event.target.value);
  };

  const handleAddInstaAccount = e => {
    if (!instaAccounts.includes(instaValue)) {
      setInstaAccounts(prevChips => [
        ...prevChips,
        instaValue.replace(/ /g, ''),
      ]);
    }
    setInstaValue('');
  };

  const handleAddOtherAccount = () => {
    if (values.platform !== '' && otherAccountValue) {
      if (
        !otherAccounts.includes(otherAccountValue) &&
        !otherAccountsChips.includes(otherAccountValue)
      ) {
        setOtherAccountsChips(prevChips => [
          ...prevChips,
          {
            account: otherAccountValue.replace(/ /g, ''),
            platform: values.platform,
          },
        ]);
        setOtherAccounts(prevAccount => [
          ...prevAccount,
          ` ${otherAccountValue.replace(/ /g, '')} (${values.platform})`,
        ]);
      }
    }
    setOtherAccountValue('');
  };

  const handleChipDelete = chip => {
    setInstaAccounts(prevChips =>
      prevChips.filter(prevChip => chip !== prevChip)
    );
    setOtherAccountsChips(prevChips =>
      prevChips.filter(prevChip => chip !== prevChip)
    );

    setOtherAccounts(prevChips =>
      prevChips.filter(prevChip => chip !== prevChip)
    );
  };

  const onSubmit = data => {
    const fullName = data.fullname;
    const email = data.email;
    const country = data.country;
    const igAccounts = instaAccounts.join();
    const otAccounts = otherAccounts.join();

    const payload = {
      ...(typeof referralId !== 'undefined' && {
        referred_by: referralId,
      }),
      full_name: fullName,
      email: email,
      country: country,
      source: 'gust_website',
      ...(igAccounts.length !== 0 && {
        ig_accounts: igAccounts,
      }),
      ...(otAccounts.length !== 0 && {
        other_accounts: otAccounts,
      }),
    };

    if (typeof referralId === 'string' && isNaN(referralId)) {
      setInfo('Invalid referral code. Please check your code and try again.');
    } else if (igAccounts.length === 0 && otherAccounts.length === 0) {
      setInfo('Please add your accounts and click on the + button');
    } else {
      axios
        .post(
          'https://prod.api.gust.io/v1/public/users/account_requests/',
          payload
        )
        .then(response => {
          if (response.data) {
            if (response.data.statusCode === 201) {
              reset();
              setSuccess(true);
              setInstaAccounts([]);
              setOtherAccounts([]);
              setOtherAccountsChips([]);
              setInstaValue('');
              setOtherAccountValue('');
            }
          }
          reset();
          clearErrors();
        })
        .catch(error => {
          setError(true);
          const errorResData = error.response.data;
          if (error.response && errorResData) {
            if (
              errorResData.data.email &&
              errorResData.data.email[0].code === 'unique'
            ) {
              setErrorMessage('We already received your request');
            }
            if (
              errorResData.data.referred_by &&
              errorResData.data.referred_by[0].code === 'does_not_exist'
            ) {
              setErrorMessage(
                'Invalid referral code. Please check your code and try again.'
              );
            }
          }
        });
    }
  };

  const renderMessages = () => {
    if (success) {
      return (
        <Alert variant="filled" severity="success">
          Your request to join Gust has been successfully sent
        </Alert>
      );
    }
    if (error) {
      return (
        <Alert variant="filled" severity="error">
          {errorMessage || 'Something went wrong.'}
        </Alert>
      );
    }
    if (info) {
      return (
        <Alert variant="filled" severity="info">
          {info}
        </Alert>
      );
    }
  };

  return (
    <form
      className="justify-content-center"
      id="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="form-group form-group-align">
        <Controller
          name="fullname"
          defaultValue=""
          as={
            <TextField
              id="fullname"
              fullWidth
              label="Full Name *"
              className={classes.textField}
              InputProps={{
                className: classes.input,
              }}
              error={!!errors.fullname}
              helperText={errors.fullname ? errors.fullname.message : null}
            />
          }
          control={control}
          rules={{
            required: 'Required',
          }}
        />
      </div>
      <div className="form-group form-group-align">
        <Controller
          name="email"
          defaultValue=""
          as={
            <TextField
              fullWidth
              id="email"
              className={classes.textField}
              InputProps={{
                className: classes.input,
              }}
              helperText={errors.email ? errors.email.message : null}
              label="Email *"
              error={!!errors.email}
            />
          }
          control={control}
          rules={{
            required: 'Required',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: 'Invalid email address',
            },
          }}
        />
      </div>

      <div className="form-group form-group-align">
        <Controller
          name="country"
          defaultValue=""
          as={
            <TextField
              id="standard-select-country-native"
              select
              fullWidth
              name="country"
              value={country}
              onChange={handleCountryChange}
              className={classes.textField}
              InputProps={{
                className: classes.input,
              }}
              SelectProps={{
                native: true,
              }}
              helperText={errors.country ? errors.country.message : null}
              label="Select your country *"
              error={!!errors.country}
            >
              {countries.map(option => (
                <option key={option.code} value={option.code}>
                  {option.name}
                </option>
              ))}
            </TextField>
          }
          control={control}
          rules={{
            required: 'Required',
          }}
        />
      </div>
      <div className="form-group form-group-align">
        <Box className={classes.box}>
          <TextField
            fullWidth
            name="insta"
            label="Instagram Accounts"
            className={classes.textField}
            value={instaValue}
            onChange={event => setInstaValue(event.target.value)}
            InputProps={{
              className: classes.input,
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <IconButton
                    className={classes.iconButton}
                    disabled={instaValue.length === 0}
                    onClick={handleAddInstaAccount}
                    size="small"
                  >
                    <SvgIcon className={classes.svgIcon}>
                      <AddIcon />
                    </SvgIcon>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box mt={1}>
          {instaAccounts.map((tag, i) => (
            <Chip
              avatar={<Avatar alt="Instagram" src={igIcon} />}
              key={i}
              label={tag}
              className={classes.chip}
              onDelete={() => handleChipDelete(tag)}
            />
          ))}
        </Box>
      </div>
      <div className="form-group form-group-align">
        <Box className={classes.otherAccountBox}>
          <Controller
            name="platform"
            defaultValue=""
            as={
              <TextField
                id="platform"
                select
                name="platform"
                value={platform}
                onChange={handlePlatformChange}
                className={classes.platformTextField}
                InputProps={{
                  className: classes.input,
                }}
                SelectProps={{
                  native: true,
                }}
                label="Platform"
                helperText={errors.platform ? errors.platform.message : null}
                error={!!errors.platform}
              >
                {platforms.map(option => (
                  <option key={option.id} value={option.value}>
                    {option.name}
                  </option>
                ))}
              </TextField>
            }
            control={control}
          />
          <TextField
            fullWidth
            name="other"
            label="Other Accounts"
            className={classes.textField}
            value={otherAccountValue}
            onChange={event => setOtherAccountValue(event.target.value)}
            disabled={platformSelected === ''}
            InputProps={{
              className: classes.input,
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <IconButton
                    className={classes.iconButton}
                    disabled={otherAccountValue.length === 0}
                    onClick={handleAddOtherAccount}
                    size="small"
                  >
                    <SvgIcon className={classes.svgIcon}>
                      <AddIcon />
                    </SvgIcon>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box mt={1}>
          {otherAccountsChips.map((tag, i) => (
            <Chip
              avatar={
                <Avatar alt="Other Accounts" src={platformIcon[tag.platform]} />
              }
              key={i}
              label={tag.account}
              className={classes.chip}
              onDelete={() => handleChipDelete(tag)}
            />
          ))}
        </Box>
      </div>
      <div>
        <button
          id="request-form-button"
          type="submit"
          className="btn btn-outline btn-xl btn-update-password"
        >
          Submit Request
        </button>
      </div>

      <div id="errors">{renderMessages()}</div>
    </form>
  );
}

export default AccountForm;
