import React from 'react';
import { Link } from 'gatsby';
import { Typography, makeStyles } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import AccountForm from '../components/AccountForm';

const useStyles = makeStyles(theme => ({
  root: {},
  title: {
    fontSize: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
    },
  },
  text: {
    marginLeft: theme.spacing(1),
  },
  link: { display: 'flex', justifyContent: 'left', color: 'white' },
}));

const RequestAccountPage = props => {
  const classes = useStyles();
  const { location } = props;
  const currentLocation = location;

  const referralId = currentLocation.search
    .slice(1)
    .split('&')[0]
    .split('=')[1];

  return (
    <Layout>
      <div id="reset-password">
        <div id="reset-password-content">
          <div className="container">
            <SEO title="Request A New Account" />
            <div className="row text-white">
              <div className="col-xl-5 col-lg-6 col-md-8 col-sm-10 mx-auto text-center form p-4">
                <div>
                  <Link
                    to="/"
                    rel="noopener noreferrer"
                    color="textPrimary"
                    className={classes.link}
                  >
                    <KeyboardBackspaceIcon />{' '}
                    <Typography className={classes.text}>Back</Typography>
                  </Link>
                </div>
                <div className="text-center logo-container">
                  <img
                    src={'https://assets.gust.io/logo_white.webp'}
                    className="rounded"
                    alt="logo"
                  />
                </div>
                <h2 className={('display-6 py-2 text-truncate', classes.title)}>
                  Request a new account
                </h2>
                <div className="px-2">
                  <AccountForm referralId={referralId} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default RequestAccountPage;
